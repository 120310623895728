import axios from "axios";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import { TestRTCSDKOptions } from "./types";
import { decomposeApiKey } from "../utils/apikey";

export async function getConfig(options: TestRTCSDKOptions): Promise<TestConfiguration> {
  let { apiKey, url, environment } = decomposeApiKey(options.apiKey, {
    local: `http://localhost:9101/v1`,
    staging: `https://api-staging.testrtc.com/v1st`,
    production: `https://api.testrtc.com/v1`,
  });

  // for local setup we are using PR:{probeId} key
  // because of kong authentication api key schema
  // so for local use need to pass local:{probeId} and not generated apiKey
  apiKey = environment === "local" ? `PR:${apiKey}` : apiKey;

  const requestUrl = `${url}/networktest-config?instanceId=${options.instanceId || ""}`;
  const headers = {
    apikey: apiKey,
  };
  const configData = await axios.get(requestUrl, { headers });
  const currentDate = new Date();
  const ProbeDate = new Date(configData?.data?.end_date);
  if (configData?.data?.end_date && ProbeDate.getTime() < currentDate.getTime()) {
    throw Error("Probe date have been Expired");
  }
  // if connection is of Genesys, do not print getConfig they have sensitive info in options field.
  if (configData.data?.ConnectionInfoName === "genesys")
    console.log("getConfig", { url, requestUrl });
  else console.log("getConfig", { url, requestUrl, config: configData.data });
  const getUserMedia = {
    checkAudio: false,
    checkVideo: false,
  };
  if (configData.data.test_list.find((x: string) => x === "CallQuality")) {
    getUserMedia["checkAudio"] = true;
  }
  if (configData.data.test_list.find((x: string) => x === "VideoQuality")) {
    getUserMedia["checkVideo"] = true;
  }
  if (!configData.data.test_list.find((x: string) => x === "GetUserMedia")) {
    configData.data.test_list.push("GetUserMedia");
  }
  if (!configData.data.test_list.find((x: string) => x === "GetUserMedia")) {
    configData.data.thorough_test_list.push("GetUserMedia");
  }
  const config: TestConfiguration = configData.data;
  const configOptions: any = {};
  configOptions["region"] = config.region?.toLowerCase();

  // for genesys https://redmine.testrtc.com/issues/8086 point 3
  // adding clientid, clientsecret mediaregion environment dialupnum
  configOptions["clientId"] = config.clientid;
  configOptions["clientSecret"] = config.clientsecret;
  configOptions["mediaRegion"] = config.mediaregion?.toLowerCase();
  configOptions["environment"] = config.environment?.toLowerCase();
  configOptions["dialUpNum"] = config.dialupnum;
  configOptions["wakeLock"] = config.wakeLock ? true : false;

  if (config.CallQuality?.useAudioFile) {
    config.CallQuality.useAudioFile = "./test.wav";
  }

  config.getUserMedia = getUserMedia;
  config.options = configOptions;
  config.API_KEY = apiKey;
  config.isProbe = true;
  config.API_URL = `${url}/networktest`;
  config.CredentialsApiUrl =
    environment === "local" ? "http://localhost:80/access" : config.CredentialsApiUrl;
  return config;
}
