import React from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import queryString from "query-string";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import { getInstanceId } from "../../utils";
import { getConfig } from "../../sdk/config";

import themes from "./themes";

// brand logos
import defaultBrandLogo from "../../assets/brand-logos/default.png";
import freshworksBrandLogo from "../../assets/brand-logos/freshworks.png";
import { restoreApiKey } from "../../utils/apikey";
import { setTokenForGenesys } from "../../test-run/test-run-logic";

const useStyles = makeStyles((theme: any) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  logoImg: {
    display: "inline-block",
    verticalAlign: "middle",
    height: 55,
    [theme.breakpoints.down("xs")]: {
      height: 28,
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export interface ILayoutContext {
  textDictionary: Record<string, string>;
  themeName: string;
}

export const LayoutContext = React.createContext<ILayoutContext>({
  textDictionary: {},
  themeName: "default",
});

const Layout = (props: any) => {
  const classes = useStyles();
  const [theme, setTheme] = React.useState<any>(themes.defaultTheme);
  const [themeName, setThemeName] = React.useState<string>("default");
  const [textDictionary, setTextDictionary] = React.useState({});

  React.useEffect(() => {
    configureLayout();
  }, []);

  const configureLayout = async () => {
    try {
      const queryParams = queryString.parse(window.location?.search);

      // this was for implicit grant method genesys
      // if (window.location.hash) {
      //   // this comes from genesys redirect
      //   setTokenForGenesys();
      // }

      // this is code authorization method genesys
      // here code will contain authorization-code
      if (queryParams?.code) {
        // this comes from genesys redirect
        setTokenForGenesys();
      }

      let apiKey = restoreApiKey(queryParams.apiKey as string);

      // window.location.hostname.includes("staging") ||
      // window.location.hostname.includes("localhost");
      const instanceId = getInstanceId();
      let { theme, textDictionary } = await getConfig({
        apiKey: apiKey?.toString() || "",
        instanceId,
      });

      if (theme && themes[theme as string]) {
        setThemeName(theme);
        const chosenTheme = themes[theme as string];
        setTheme(chosenTheme);
      }
      if (textDictionary) {
        setTextDictionary(JSON.parse(textDictionary.toString()));
      }
    } catch (err) {
      console.log("Configure Layout", err.message);
    }
  };

  const getBrandLogo = (themeName: string) => {
    switch (themeName) {
      case "default":
        return defaultBrandLogo;
      case "freshworks":
        return freshworksBrandLogo;
      default:
        return defaultBrandLogo;
    }
  };

  return (
    <LayoutContext.Provider
      value={{
        textDictionary,
        themeName,
      }}
    >
      <MuiThemeProvider theme={createMuiTheme(theme)}>
        <CssBaseline />
        <AppBar position="absolute" color="default" className={classes.appBar}>
          <Toolbar>
            <img className={classes.logoImg} src={getBrandLogo(themeName)} alt="brand-logo" />
          </Toolbar>
        </AppBar>
        <main className={classes.layout}>{props.children}</main>
      </MuiThemeProvider>
    </LayoutContext.Provider>
  );
};

export default Layout;
